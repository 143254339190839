import {  createAppContainer } from 'react-navigation';
import { createStackNavigator } from "react-navigation-stack";
import Login from "./app/Login";
import Signup from "./app/Signup";
import  SearchScreen  from "./app/screens/SearchScreen";

const navigator = createStackNavigator({
  login : {
    screen: Login,
  //   navigationOptions: {
  //     header: null,
  // }
} ,
  signup :{
    screen: Signup,
  //   navigationOptions: {
  //     header: null,
  // }
}  ,
  search : SearchScreen,
},{
  initialRouteName : 'signup',
  defaultNavigationOptions:{
    title: 'FRPApp'
     
  }
}

);
export default createAppContainer(navigator);