import React, { useState} from 'react';
import { View, Text, StyleSheet } from 'react-native';
import SearchBar from "./../components/SearchBar";

const SearchScreen = () =>{
  const [tern, setTern] = useState('');

    return <View>
        <SearchBar tern={tern} onTernChange={() =>setTern    }/>
        <Text> Search Screen</Text>
    </View>
};

const styles = StyleSheet.create({
    
});

export default SearchScreen;